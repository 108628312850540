<script setup>
import VueTypes from "vue-types";

const props = defineProps({
  visible: VueTypes.bool.def(false),
  text: VueTypes.string,
  title: VueTypes.string,
  modal: VueTypes.object.def({
    ok: "Onayla",
    cancel: "İptal",
  }),
});
const emit = defineEmits([
  "is:ok",
  "is:complete",
  "is:cancel",
  "update:loading",
]);

const onOkModal = () => {
  emit("is:ok");
};

const onCancelModal = () => {
  emit("is:cancel");
};
</script>

<template>
  <div v-if="visible">
    <a-modal
      :visible="visible"
      :title="title"
      :ok-text="modal.ok"
      :cancel-text="modal.cancel"
      @ok="onOkModal"
      @cancel="onCancelModal"
      class="verification-modal base-form"
    >
      <p v-if="text" v-html="text" class="is-description"></p>
    </a-modal>
  </div>
</template>

<style lang="scss">
.verification-modal {
  .ant-modal-footer {
    .ant-btn-primary {
      &:disabled {
        color: var(--theme-primary-color);
        opacity: 0.7;
      }
    }
  }
}
</style>
